<script>
import NativeCampaignManagementEditView from './NativeCampaignManagementEditView'

export default {
  name: 'NativeCampaignManagementDuplicateView',
  extends: NativeCampaignManagementEditView,
  data () {
    return {
      isDuplicate: true
    }
  },
  created () {
    this.isDuplicate = true
  }
}
</script>
